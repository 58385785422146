<template>
  <Container space-before space-after>
    <ProductList
      :subtext="subtext"
      :taxon-id="taxon"
      :headline="headline"
      :headline-anchor="headlineAnchor"
      :more-label="linkLabel"
      :more-href="link"
      :layout="layout"
      :sort="sort"
      :element-id="element.id"
      :element-name="element.name"
      :only-live-products="onlyLiveProducts"
    />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      taxon() {
        return this.getValue("taxon")
      },
      headline() {
        return this.getValue("headline")
      },
      headlineAnchor() {
        return this.getIngredient("headline")?.domId
      },
      link() {
        return this.getIngredient("link")?.linkUrl
      },
      linkLabel() {
        return this.getValue("link")
      },
      subtext() {
        return this.getValue("subtext")
      },
      layout() {
        return this.getValue("layout")
      },
      sort() {
        return this.getValue("sort")
      },
      onlyLiveProducts() {
        return this.getValue("only_live_products")
      },
    },
  }
</script>
